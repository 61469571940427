.TuckedImageSection {
  display: grid;
  align-items: center;
  gap: clamp(28px, calc(42vw / 7.68), 42px) clamp(82px, calc(82vw / 7.68), 164px);
  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    .img {
      order: -1;
    }
  }
  header {
    max-width: calc(587rem / 16);
    .paragraph {
      margin-top: clamp(12px, calc(16vw / 7.68), 20px);
    }
    .cta {
      margin-top: clamp(24px, calc(28vw / 7.68), 36px);
    }
  }
  .img {
    margin-left: calc(var(--pageMargin) * -1);
    max-width: clamp(282px, calc(520vw / 7.68), 520px);
    width: 100%;
    position: relative;
    .Line,
    .Brushes,
    .Sygnet {
      position: absolute;
    }
    .Line {
      z-index: -1;
      width: 130%;
      left: 0;
      top: 0;
      path {
        animation: Line 3s var(--easing) both;
        @keyframes Line {
          from {
            stroke-dasharray: 0 1166;
          }
          to {
            stroke-dasharray: 1166 1166;
          }
        }
      }
    }
    .Brushes {
      width: clamp(44px, calc(99vw / 7.68), 99px);
      right: 0;
      top: 0;
      path {
        transform-origin: bottom left;
        transform-box: fill-box;
        &:nth-child(1) {
          animation-delay: 1s;
        }
        &:nth-child(2) {
          animation-delay: 1.1s;
        }
        &:nth-child(3) {
          animation-delay: 1.2s;
        }
        animation: Scale 1s var(--easing) both;
      }
    }
    .Sygnet {
      width: clamp(88px, calc(162vw / 7.68), 162px);
      right: 0;
      bottom: -5%;
      animation: Scale 1s var(--easing) both;
      animation-delay: 2s;
    }
    @keyframes Scale {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }
  }
}
